import { groupBy, flatMap } from 'lodash'

export class SortUtil {
  static sortStringsByKey(key, arr) {
    return arr.sort((a, b) => {
      const aLower = a[key].toLowerCase()
      const bLower = b[key].toLowerCase()

      if (aLower > bLower) return -1
      if (bLower > aLower) return 1
      return 0
    })
  }

  static sortStrings(arr) {
    return arr.sort((a, b) => {
      const aLower = a.toLowerCase()
      const bLower = b.toLowerCase()

      if (aLower > bLower) return 1
      if (bLower > aLower) return -1
      return 0
    })
  }

  static groupByKey(key, arr) {
    return groupBy(arr, key)
  }

  static sortGroupByKey(sortkey, groupKey, arr) {
    const sorted = SortUtil.sortStringsByKey(sortkey, arr)
    const grouped = SortUtil.groupByKey(groupKey, sorted)
    return grouped
  }

  static deepSortByKey(elemKey, sortKey, arr) {
    return flatMap(arr, (elem) => ({
      ...elem,
      [elemKey]: elem[elemKey].sort((a, b) =>
        a[sortKey] > b[sortKey] ? 1 : -1
      )
    }))
  }

  static createSortAcessorNullAlwaysBottom(sortDefinition, sortDirection) {
    const nullCoalescingValue = sortDirection === 'asc' ? Infinity : -Infinity
    return sortDefinition.sortDataGetter
      ? (row) =>
          sortDefinition.sortDataGetter({
            rowData: row,
            dataKey: sortDefinition.dataKey
          }) ?? nullCoalescingValue
      : (row) => row[sortDefinition.dataKey] ?? nullCoalescingValue
  }
}
